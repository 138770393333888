import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header px-3">
        <div className="bg-slate-800 px-20 py-12 rounded shadow-xl shadow-slate-800">
          <h1 className="text-white text-3xl font-bold mb-3">
            Watcher
          </h1>
          <h2 className='text-white text-xl'>
            Coming soon the definitive app to watch your apps...
          </h2>
        </div>
      </header>
    </div>
  );
}

export default App;
